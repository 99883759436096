import request from '@/utils/request'

export function getTerm(data) {
    return request({
        url: `/api/front/announce/term`,
        method: 'get'
    })
}

export function getDisclaimer(data) {
    return request({
        url: `/api/front/announce/disclaimer`,
        method: 'get'
    })
}

export default { getTerm, getDisclaimer }